var constants = {

	modules: {

		usps: {

			sliderMaxWidth: 979

		}

	}

};

constants.modules.usps.sliderMaxWidth = 1249;



